import React, { Component } from "react";
import Navbar from "../headercomponents/navbar.jsx";
import Footer from "../FooterComponents/footer.js";
import Jumbotron from "../JumbotronComponents/jumbotron.jsx";
class BlogDenmark extends Component {
  render() {
    return (
      <div>
        <Navbar />
        <Jumbotron
          title="Denmark 2014"
          subtitle="Training in Denmark"
          className="denmark"
        />
        <div className="container">
          <p>
            {" "}
            My first destination for this 2014 trip was Herlov, Denmark
            (https://goo.gl/maps/wgRXd ) to receive a training at the one of our
            offices. After a 12 hour flight from Mauritius to Paris, I had taken
            a connection flight to Copenhagen. From my window seat, I was able
            to have a panoramic view of the European countries and sea that we
            were passing over that Sunday morning of the 25th of May. High up in
            the sky, I remembered enjoyed a quick coffee and cake while feeling
            the bright sunshine over me. From the airport of Copenhagen, I then
            took a taxi to go to my hotel and for one hour, I was able to enjoy
            a closer view of Denmark; I was pleasantly surprised by the big
            green trees on each side of the clean road, green grass, blue sky up
            and warm sunlight on my skin. On my arrival at the hotel Herlev Kro,
            (http://www.tripadvisor.com/Hotel_Review-g1370480-d251355-Reviews-Herlev_Kro_Hotel-Herlev_Ballerup_Municipality_Copenhagen_Region_Zealand.html
            ), at around noon. I was a bit embarrassed with my 3 suitcases (One
            large, one medium and one small), one hand bag and finally a laptop
            backpack and felt the need to explain to the lady at the reception
            that those luggage were not solely for the 6 days stay in
            Copenhagen, but for a longer trip because it appeared as if I was
            carrying my whole house with me ;-) Having said that, I learnt that
            even if sometimes, it feels comfortable to have EVERYTHING handy,
            from the scissors and sewing kit to all bathroom products and
            accessories, it does feel very heavy and awkward to carry and lift
            such luggage around, even with wheels ;-). Indeed, there will be
            many such anecdotes, which will teach me valuable lessons about
            packing ;-) That Sunday afternoon was spent unpacking, reconnecting
            with my family, relaxing and discovering the long daylight till
            11:30 pm. I loved Herlov for its nice and peaceful atmosphere. Every
            afternoon, I would take strolls around the blocks to see around.
            Though all shops were closed early and there were not many
            activities in that small city, I enjoyed just walking around and
            enjoying the cold breeze, nice sun, the lovely sceneries (which
            included big green trees and beautiful flowers) the around me. The
            next Monday morning I woke up, ready to go to office for the
            training. The reception offered me a map of the place and told me
            that the office was only a few blocks away and that I could easily
            walk. Though it looked simple, as I walked, I did get lost with my
            map and but found it adventurous to be flipping it around, to find
            my direction. It was also an opportunity to pay attention to all
            that was around me, the buildings, the board signs, the name of the
            streets etc. I found that the roads were well-ordered and the
            drivers particularly polite. Although when I set out, I was feeling
            cold with the chilling breeze blowing over my face, when I finally
            reached the office building, I was a little sweaty. In fact, though
            the summer in Denmark was still cold for me, being accustomed to
            tropical climates, at times, I did feel warm. The training was an
            opportunity to meet up with many very competent software engineers
            of the organization and it was a privilege to receive personal
            training there. During that week, I even felt that maybe I should
            have stayed in the development field longer because there was still
            so much more to learn and work on. However, I reminded myself that
            the decision to move from development to project management was
            based on my need to enhance my interpersonal and managerial skills.
            Also, the new job responsibility would still allow me to learn about
            new technologies and concept; I might not always directly contribute
            to the development phase, but I would still need to understand and
            use them. During my stay, I was able to discover the working style
            of my fellow colleagues and the Danish work culture in general. I
            read that Danish people were presumably among the happiest people in
            the world, both at home and in office.
            http://www.visitdenmark.co.uk/en-gb/denmark/art/happiest-people-world
            ) I cannot confirm that but I was struck by the hassle-free
            atmosphere around me there; people going everywhere, including work,
            on their bicycle, the air smelled very pure and fresh, people doing
            their jogging every afternoon, relatively less noise even in the
            capital city, Copenhagen. I later also discovered that Scandinavian
            languages were the only languages which had a word for “Happiness at
            Work”, Arbejdsglaede. ( http://www.whattheheckisarbejdsglaede.com/
            ). Luckily, there was a public holiday on the Thursday of that week,
            I was therefore able to visit Copenhagen. I have to confess that I
            had very little knowledge about Denmark
            (http://en.wikipedia.org/wiki/Denmark ), when I came there. I only
            knew a few facts like where it was situated and the name of the
            Capital city ;-) The Wednesday afternoon, my colleague printed a map
            of the city and suggested a few places that I should visit. This was
            how I went to discover Copenhagen, one of most beautiful cities
            which I have seen so far ☺ I will reserve a separate post to
            describe that special visit ☺
          </p>
          <ul>
            Lessons of the Trip:
            <li className="item">
              I learnt that no matter which level you rise up in your career,
              there will always be topics with which you will not be familiar
              with. The fact is nobody knows everything. Therefore, there is no
              shame or embarrassment in not knowing something. Never hesitate to
              ask questions. And if it concerns basic things which you feel that
              you should be knowing, then formulate your question appropriately,
              for example, by asking where you can get the answers, so that it
              does not appear that you rely on spoon-feeding.{" "}
            </li>
            <li className="item">
              In case of one to one training, it is fine to ask any question
              that you may have if you see that you have the time. However, in
              group training, others might do the same, which might lead to the
              training going beyond the allocated time slot. Therefore, it is
              often advisable to ask questions which are not directly related to
              the topic of the training or related to topics which you should
              already be aware of, after the training session. Thus you will not
              be wasting the time of others but will still get your answers{" "}
            </li>
          </ul>
        </div>
        <Footer />
      </div>
    );
  }
}

export default BlogDenmark;
