import Navbar from "../headercomponents/navbar.jsx";
import Footer from "../FooterComponents/footer.js";

import React from "react";
// import ReactDOM from "react-dom";
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
import { React_Bootstrap_Carousel } from "react-bootstrap-carousel";

import image1 from "../CarouselComponents/images/Denmark/denmark1.JPG";
import image2 from "../CarouselComponents/images/Denmark/denmark2.JPG";
import image3 from "../CarouselComponents/images/Denmark/denmark3.JPG";
import image4 from "../CarouselComponents/images/Denmark/denmark4.JPG";

import chile1 from "../CarouselComponents/images/Chile/chile1.jpg";
import chile2 from "../CarouselComponents/images/Chile/chile2.jpg";
import chile3 from "../CarouselComponents/images/Chile/chile3.jpg";

import s1 from "../CarouselComponents/images/Singapore/s1.jpg";
import s2 from "../CarouselComponents/images/Singapore/s2.jpg";
import s3 from "../CarouselComponents/images/Singapore/s3.jpg";
import s4 from "../CarouselComponents/images/Singapore/s4.jpg";
import s5 from "../CarouselComponents/images/Singapore/s5.jpg";
import s6 from "../CarouselComponents/images/Singapore/s6.jpg";

import n1 from "../CarouselComponents/images/NY/n1.JPG";
import n2 from "../CarouselComponents/images/NY/n2.JPG";
import n3 from "../CarouselComponents/images/NY/n3.JPG";
import n4 from "../CarouselComponents/images/NY/n4.JPG";
import n5 from "../CarouselComponents/images/NY/n5.JPG";
import n6 from "../CarouselComponents/images/NY/n6.jpg";
import n7 from "../CarouselComponents/images/NY/n7.jpg";
import n8 from "../CarouselComponents/images/NY/n8.JPG";
import n9 from "../CarouselComponents/images/NY/n9.JPG";
import n10 from "../CarouselComponents/images/NY/n10.JPG";

import m1 from "../CarouselComponents/images/Mexico/m1.jpg";
import m2 from "../CarouselComponents/images/Mexico/m2.jpg";

import p1 from "../CarouselComponents/images/Panama/p1.jpg";
import p2 from "../CarouselComponents/images/Panama/p2.jpg";
import p3 from "../CarouselComponents/images/Panama/p3.jpg";
import p4 from "../CarouselComponents/images/Panama/p4.jpg";
import p5 from "../CarouselComponents/images/Panama/p5.jpg";
import p6 from "../CarouselComponents/images/Panama/p6.jpg";
import p7 from "../CarouselComponents/images/Panama/p7.jpg";
import p8 from "../CarouselComponents/images/Panama/p8.jpg";
import p9 from "../CarouselComponents/images/Panama/p9.jpg";
import p10 from "../CarouselComponents/images/Panama/p10.jpg";
import p11 from "../CarouselComponents/images/Panama/p11.jpg";
import p12 from "../CarouselComponents/images/Panama/p12.jpg";

import sb1 from "../CarouselComponents/images/Panama/sb1.jpg";
import sb2 from "../CarouselComponents/images/Panama/sb2.jpg";
import sb3 from "../CarouselComponents/images/Panama/sb3.jpg";

import rd1 from "../CarouselComponents/images/RD/rd1.JPG";
import rd2 from "../CarouselComponents/images/RD/rd2.JPG";
import rd3 from "../CarouselComponents/images/RD/rd3.JPG";
import rd4 from "../CarouselComponents/images/RD/rd4.JPG";

class BlogCarousel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      autoplay: true,
    };
  }
  onSelect = (active, direction) => {
    console.log(`active=${active} && direction=${direction}`);
  };
  slideNext = () => {
    this.slider.slideNext();
  };
  slidePrev = () => {
    this.slider.slidePrev();
  };
  goToSlide = () => {
    this.slider.goToSlide(4);
  };
  autoplay = () => {
    this.setState({ autoplay: !this.state.autoplay });
  };
  _changeIcon = () => {
    let { leftIcon, rightIcon } = this.state;
    if (leftIcon && rightIcon) {
      this.setState({
        leftIcon: undefined,
        rightIcon: undefined,
      });
    } else {
      this.setState({
        leftIcon: <span className="fa fa-glass" />,
        rightIcon: <span className="fa fa-music" />,
      });
    }
  };
  render() {
    let { leftIcon, rightIcon } = this.state;
    return (
      <div>
        <Navbar />

        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12" style={{ marginTop: 20 }}>
              <React_Bootstrap_Carousel
                animation={true}
                autoplay={this.state.autoplay}
                slideshowSpeed={7000}
                leftIcon={leftIcon}
                rightIcon={rightIcon}
                onSelect={this.onSelect}
                ref={(r) => (this.slider = r)}
                version={4}
              >
                <div>
                  <div className="carousel-caption">Denmark 2014</div>
                  <img
                    alt="image1"
                    style={{ width: "100%", height: "100%" }}
                    src={image1}
                  />
                </div>
                <div>
                  <div className="carousel-caption">Denmark 2014</div>
                  <img
                    alt="image2"
                    style={{ width: "100%", height: "100%" }}
                    src={image2}
                  />
                </div>
                <div>
                  <img
                    alt="image3"
                    style={{ width: "100%", height: "100%" }}
                    src={image3}
                  />
                  <div className="carousel-caption">Denmark 2014</div>
                </div>
                <div>
                  <img
                    alt="image4"
                    style={{ width: "100%", height: "100%" }}
                    src={image4}
                  />
                  <div className="carousel-caption">Denmark 2014</div>
                </div>

                <div>
                  <div className="carousel-caption">Mexico 2014</div>
                  <img
                    alt="image5"
                    style={{ width: "100%", height: "100%" }}
                    src={m1}
                  />
                </div>
                <div>
                  <div className="carousel-caption">Mexico 2014</div>
                  <img
                    alt="image6"
                    style={{ width: "100%", height: "100%" }}
                    src={m2}
                  />
                </div>

                <div>
                  <div className="carousel-caption">Panama 2014</div>
                  <img
                    alt="image7"
                    style={{ width: "100%", height: "100%" }}
                    src={p1}
                  />
                </div>
                <div>
                  <div className="carousel-caption">Panama 2014</div>
                  <img
                    alt="image8"
                    style={{ width: "100%", height: "100%" }}
                    src={p2}
                  />
                </div>
                <div>
                  <div className="carousel-caption">Panama 2014</div>
                  <img
                    alt="image9"
                    style={{ width: "100%", height: "100%" }}
                    src={p3}
                  />
                </div>
                <div>
                  <div className="carousel-caption">Panama 2014</div>
                  <img
                    alt="image10"
                    style={{ width: "100%", height: "100%" }}
                    src={p4}
                  />
                </div>
                <div>
                  <div className="carousel-caption">Panama 2014</div>
                  <img
                    alt="image11"
                    style={{ width: "100%", height: "100%" }}
                    src={p5}
                  />
                </div>
                <div>
                  <div className="carousel-caption">Panama 2014</div>
                  <img
                    alt="image12"
                    style={{ width: "100%", height: "100%" }}
                    src={p12}
                  />
                </div>
                <div>
                  <div className="carousel-caption">Panama 2014</div>
                  <img
                    alt="image13"
                    style={{ width: "100%", height: "100%" }}
                    src={p6}
                  />
                </div>
                <div>
                  <div className="carousel-caption">Panama 2014</div>
                  <img
                    alt="image14"
                    style={{ width: "100%", height: "100%" }}
                    src={p7}
                  />
                </div>
                <div>
                  <div className="carousel-caption">
                    San Blas Islands - North West of Panama facing Carabbean Sea
                    - 2014
                  </div>
                  <img
                    alt="image15"
                    style={{ width: "100%", height: "100%" }}
                    src={p8}
                  />
                </div>
                <div>
                  <div className="carousel-caption">
                    San Blas Islands - North West of Panama facing Carabbean Sea
                    - 2014
                  </div>
                  <img
                    alt="image16"
                    style={{ width: "100%", height: "100%" }}
                    src={p9}
                  />
                </div>
                <div>
                  <div className="carousel-caption">
                    San Blas Islands - North West of Panama facing Carabbean Sea
                    - 2014
                  </div>
                  <img
                    alt="image17"
                    style={{ width: "100%", height: "100%" }}
                    src={p10}
                  />
                </div>
                <div>
                  <div className="carousel-caption">
                    San Blas Islands - North West of Panama facing Carabbean Sea
                    - 2014
                  </div>
                  <img
                    alt="image18"
                    style={{ width: "100%", height: "100%" }}
                    src={p11}
                  />
                </div>
                <div>
                  <div className="carousel-caption">
                    San Blas Islands - North West of Panama facing Carabbean Sea
                    - 2014
                  </div>
                  <img
                    alt="image19"
                    style={{ width: "100%", height: "100%" }}
                    src={sb1}
                  />
                </div>
                <div>
                  <div className="carousel-caption">
                    San Blas Islands - North West of Panama facing Carabbean Sea
                    - 2014
                  </div>
                  <img
                    alt="image20"
                    style={{ width: "100%", height: "100%" }}
                    src={sb2}
                  />
                </div>
                <div>
                  <div className="carousel-caption">
                    San Blas Islands - North West of Panama facing Carabbean Sea
                    - 2014
                  </div>
                  <img
                    alt="image21"
                    style={{ width: "100%", height: "100%" }}
                    src={sb3}
                  />
                </div>

                <div>
                  <img
                    alt="image22"
                    style={{ width: "100%", height: "100%" }}
                    src={rd1}
                  />
                  <div className="carousel-caption">
                    Dominican Republic 2014
                  </div>
                </div>
                <div>
                  <img
                    alt="image23"
                    style={{ width: "100%", height: "100%" }}
                    src={rd2}
                  />
                  <div className="carousel-caption">
                    Dominican Republic 2014
                  </div>
                </div>
                <div>
                  <img
                    alt="image24"
                    style={{ width: "100%", height: "100%" }}
                    src={rd3}
                  />
                  <div className="carousel-caption">
                    Dominican Republic 2014
                  </div>
                </div>
                <div>
                  <img
                    alt="image25"
                    style={{ width: "100%", height: "100%" }}
                    src={rd4}
                  />
                  <div className="carousel-caption">
                    Dominican Republic 2014
                  </div>
                </div>

                <div>
                  <img
                    alt="image26"
                    style={{ width: "100%", height: "100%" }}
                    src={chile1}
                  />
                  <div className="carousel-caption">Chile 2014</div>
                </div>
                <div>
                  <div className="carousel-caption">Chile 2014</div>
                  <img
                    alt="image27"
                    style={{ width: "100%", height: "100%" }}
                    src={chile2}
                  />
                </div>
                <div>
                  <div className="carousel-caption">Chile 2014</div>
                  <img
                    alt="image28"
                    style={{ width: "100%", height: "100%" }}
                    src={chile3}
                  />
                </div>

                <div>
                  <div className="carousel-caption">New York 2014</div>
                  <img
                    alt="image29"
                    style={{ width: "100%", height: "100%" }}
                    src={n1}
                  />
                </div>
                <div>
                  <div className="carousel-caption">New York 2014</div>
                  <img
                    alt="image30"
                    style={{ width: "100%", height: "100%" }}
                    src={n2}
                  />
                </div>
                <div>
                  <div className="carousel-caption">New York 2014</div>
                  <img
                    alt="image31"
                    style={{ width: "100%", height: "100%" }}
                    src={n3}
                  />
                </div>
                <div>
                  <div className="carousel-caption">New York 2014</div>
                  <img
                    alt="image32"
                    style={{ width: "100%", height: "100%" }}
                    src={n4}
                  />
                </div>
                <div>
                  <div className="carousel-caption">New York 2014</div>
                  <img
                    alt="image33"
                    style={{ width: "100%", height: "100%" }}
                    src={n5}
                  />
                </div>
                <div>
                  <div className="carousel-caption">New York 2014</div>
                  <img
                    alt="image34"
                    style={{ width: "100%", height: "100%" }}
                    src={n6}
                  />
                </div>
                <div>
                  <div className="carousel-caption">New York 2014</div>
                  <img
                    alt="image35"
                    style={{ width: "100%", height: "100%" }}
                    src={n7}
                  />
                </div>
                <div>
                  <div className="carousel-caption">New York 2014</div>
                  <img
                    alt="image36"
                    style={{ width: "100%", height: "100%" }}
                    src={n8}
                  />
                </div>
                <div>
                  <div className="carousel-caption">New York 2014</div>
                  <img
                    alt="image37"
                    style={{ width: "100%", height: "100%" }}
                    src={n9}
                  />
                </div>
                <div>
                  <div className="carousel-caption">New York 2014</div>
                  <img
                    alt="image38"
                    style={{ width: "100%", height: "100%" }}
                    src={n10}
                  />
                </div>
                <div>
                  <div className="carousel-caption">Singapore 2015</div>
                  <img
                    alt="image39"
                    style={{ width: "100%", height: "100%" }}
                    src={s1}
                  />
                </div>
                <div>
                  <div className="carousel-caption">Singapore 2015</div>
                  <img
                    alt="image40"
                    style={{ width: "100%", height: "100%" }}
                    src={s2}
                  />
                </div>
                <div>
                  <div className="carousel-caption">Singapore 2015</div>
                  <img
                    alt="image41"
                    style={{ width: "100%", height: "100%" }}
                    src={s3}
                  />
                </div>
                <div>
                  <div className="carousel-caption">Singapore 2015</div>
                  <img
                    alt="image42"
                    style={{ width: "100%", height: "100%" }}
                    src={s4}
                  />
                </div>
                <div>
                  <div className="carousel-caption">Singapore 2015</div>
                  <img
                    alt="image43"
                    style={{ width: "100%", height: "100%" }}
                    src={s5}
                  />
                </div>
                <div>
                  <div className="carousel-caption">Singapore 2015</div>
                  <img
                    alt="image44"
                    style={{ width: "100%", height: "100%" }}
                    src={s6}
                  />
                </div>
              </React_Bootstrap_Carousel>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
export default BlogCarousel;
// ReactDOM.render(<BlogCarousel />, document.getElementById("BlogCarousel"));
