import React, { Component } from "react";
import "../FooterComponents/footer.css";

class Footer extends Component {
  render() {
    return (
      <footer className="blog-footer">
        <div className="container">
          <span className="text-muted">
            EnrichingTravels &#169; {new Date().getFullYear()}
          </span>
        </div>
      </footer>
    );
  }
}

export default Footer;
