import React, { Component } from "react";
// import logo from './logo.svg';
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";

//components
import HomePage from "./components/Pages/HomePage.js";
import About from "./components/Pages/About.jsx";
import BlogPostNewYork from "./components/Pages/BlogPostNewYork.jsx";
import BlogDenmark from "./components/Pages/BlogDenmark.jsx";
import BlogPostSingapore from "./components/Pages/BlogPostSingapore.jsx";
import BlogCarousel from "./components/CarouselComponents/BlogCarousel.jsx";
import BlogPostPanamaGanesh from "./components/Pages/BlogPostPanamaGanesh.jsx";
import BlogPostClosing2018 from "./components/Pages/BlogPostClosing2018.jsx";
import { EnrichingTravelsReloaded } from "./components/AdditionalComponents/EnrichingTravelsReloaded.js";
import BlogPandemic from "./components/Pages/BlogPandemic.jsx";
import BlogLessonsLearnedPast15Years from "./components/Pages/BlogLessonsLearnedPast15Years";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Router>
          <div>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/about" component={About} />
            <Route exact path="/BlogPostNewYork" component={BlogPostNewYork} />
            <Route exact path="/BlogDenmark" component={BlogDenmark} />
            <Route
              exact
              path="/BlogPostSingapore"
              component={BlogPostSingapore}
            />
            <Route exact path="/BlogCarousel" component={BlogCarousel} />
            <Route
              exact
              path="/BlogPostPanamaGanesh"
              component={BlogPostPanamaGanesh}
            />
            <Route
              exact
              path="/BlogPostClosing2018"
              component={BlogPostClosing2018}
            />
            <Route
              exact
              path="/EnrichingTravelsReloaded"
              component={EnrichingTravelsReloaded}
            />
            <Route exact path="/BlogPandemic" component={BlogPandemic} />
            <Route
              exact
              path="/BlogLessonsLearnedPast15Years"
              component={BlogLessonsLearnedPast15Years}
            />
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
