import React, { Component } from "react";
import Navbar from "../headercomponents/navbar.jsx";
import Footer from "../FooterComponents/footer.js";
import Jumbotron from "../JumbotronComponents/jumbotron.jsx";
class BlogPostPanamaGanesh extends Component {
  render() {
    return (
      <div>
        <Navbar />
        <Jumbotron
          title="Panama - Ganesh Chaturthi 2014"
          subtitle="Meeting Ganpathi in Panama"
        />
        <div className="container">
          <p>
            Since I am a Hindu, I should of course talk about Lord Ganesha in my
            first blog because Hindus always pray to Lord Ganesha before
            starting any activity or making appeal to any other god. Last
            weekend was Ganesh Chaturthi, one important festival for the Maratha
            community, to which I belong. It was the Friday 29th August 2014;
            the start of the celebrations Ganesh Chaturthi. Of course, in the
            morning in Panama, it was already the evening back in Mauritius and
            everybody was busy, going to do prayers at the temples or at
            relatives’ and friends’ residences, eating nice indo-Mauritian food,
            watching the dance performances, or dancing and singing together.
            Ganesh Chaturthi is usually celebrated for two days. In Mauritius,
            it is the second day of the celebrations which is a public holiday,
            and therefore people, especially from the Maratha community, would
            usually take a leave to attend the pooja (prayers), called Murthi
            Stapan, done in the morning of the eve, which this year was a
            Friday. During that prayer, mantras and rituals are carried out by
            the Hindu priest to invoke Lord Ganesha to welcome into the Ganesh
            murthi (made of clay) and be our guest during this festival. Then
            prayers, flowers, fruits, water, Prasad, sweets among other
            important items are offered to the Lord Ganesha to welcome him for
            his visit. Details about the festival can be found on various sites,
            including: http://en.wikipedia.org/wiki/Ganesh_Chaturthi{" "}
          </p>
          <p>
            I woke up very late that Friday morning. I therefore felt very angry
            at myself at first because on that day, we should normally wake up
            early because the prayers should start very early. Then I decided
            that I should be more kind to myself especially that I have hectic
            days at work. Obviously by the time that I woke up, my family and
            relatives in Mauritius, were all offline because they were out of
            home, for the celebrations. Though I felt that I was missing
            something very precious from my country, I said that I too should
            still try to celebrate on my side here. As I was remembering the
            past Ganesh Chaturthi of 2013, I felt the courage that I should do
            what I had planned for the day, Lord Ganesh always gives us courage
            and strength to achieve what we want. It was my first leave from
            office since I left Mauritius for the trip and I wanted to devote
            the day to Ganpathi. So, determined to stick to the plan, even on my
            own, I got ready, did a fast prayers to my own Ganpathi murthi which
            I had brought with me from Mauritius and set out to the nearby Mall,
            MultiPlaza (https://goo.gl/maps/l81FX ), where I took a taxi which
            took me $15 to go to Tumba Muerto(https://goo.gl/maps/73Sxd ), in
            Panama
            City(https://www.google.com.pa/maps/search/Panama+city,+panama/@9.0310334,-79.516727,13z/data=!3m1!4b1!4m5!2m4!3m3!1sPanama+city,+panama!2sTumba+Muerto,+Panama!3s0x8faca9d2eae92ce5:0x8ff7afbf8b083fda?hl=en
            ), where there was a mandir, named Templo Hindu
            (https://goo.gl/maps/aaj6K ), on the top on a small hill. I had
            previously been there one Sunday. I just loved the place, the small
            road up the hill from the main road, was lined with tall trees. Once
            you are up the hill, you see a nice building with Indian
            architecture, in the middle of a big courtyard. Standing outside,
            you could have a panoramic view of Panama City. It was some 20
            minutes from Punta Pacifica, where I lived. But that day, since it
            was week day, salary day and around noon, it took the taxi more
            time. I had that feeling of restlessness, that I usually feel when I
            am doing something new and I do not know how things will be and
            therefore cannot wait for the moment to pass for me to find out.
            Well, when we finally arrived inside the big courtyard of the
            temple, we saw a few workers doing some renovation in front of the
            main entrance of the temple. My heart fell one beat down as the
            driver asked one man whether the temple was closed. “Use the door on
            the side”, I understood from his gesture and my little Spanish. So,
            I smiled while the driver did a short turn to take me the few metres
            to the other door. I thanked him with all my heart as I got out. As
            I walked towards the few steps to the door, I felt really happy as I
            felt the warmth of the sun on myself, the day was a beautiful one,
            with bright sunshine. It was almost 1 pm, but contrary to every
            afternoon where the sky of Panama City would start to be covered by
            thick deep dark clouds, followed the heavy rainfall, thunder and
            lightning, that afternoon seemed to be blessed by Ganpathi ☺
          </p>
          <p>
            As I entered the temple from the rear, I noticed that there was
            nobody inside. I walked to the area where there were the Gods’
            statues and noticed fresh beautiful flower bouquets in front of
            them, but apart from that there were nothing special. I bowed my
            head in front of the Gods, did a short prayer. Then I turned,
            disappointed. But, then as I raised my head, my eyes came across two
            Ganesh murthis, which were standing very beautifully on the other
            side of the room, facing the main entrance☺. There was Ganesh
            Chaturthi celebration in the temple!! I almost run to the place and
            bowed down in prayer. I felt a sense of satisfaction to have finally
            made it to be close to Ganpathi. Then, I went to sit on one of the
            soft mattresses on the floor, which were probably left there after
            the Murthi Stapan pooja in the morning.
          </p>
          <p>
            As I sit there, took some pictures with my camera. I took some with
            my mobile phone, to show to my family on skype, but there was no
            internet connection. Therefore, I started to close my eyes in
            meditation, feeling free after a very long time. I had indeed not
            done any meditation since I left Mauritius. The only prayers I did
            were short or listening to some prayers on YouTube, or just talking
            to God, each time that I was in some difficulty. But that day, there
            were no problems!; I felt so lucky to be in such a beautiful mandir,
            having the space all to myself. The mandir was indeed a big hall
            with white walls, with a big wooden entrance door, which was closed
            for that day. As you enter, in front were the Ganesh murthis for the
            festival, on the left there were the statues and on the right, there
            were another door which leads to a balcony over seeing Panama City.
            As I closed my eyes, I could hear the sounds of the faraway city,
            but also that of the soft breeze and chirping birds outside!! I
            could also feel the warmth of the sun coming inside from the door on
            the right which was open. After a long time, I did not think of work
            and it made me feel so light. I remembered the Ganesh Chaturthi of
            past years, which have been full of events. But I did not feel sad,
            I felt strong and full of faith that God knows what he was doing and
            the he will protect my family; I did not have to worry. Then a few
            people came, to pray, followed by some young students from the
            nearby Technological University of Panama (https://goo.gl/maps/Mn3KA
            ). I talked to one of the men who came to pray with his kids and he
            told me that there is artee (prayers) in the evenings. I also came
            to know that it was a gujrati mandir.
          </p>
          <p>
            Later, a middle-aged lady came to clean around the ganpathi place
            and talked to me. She was actually the wife of the priest and stayed
            in a house annexed behind the mandir. It was actually a funny
            conversation because she did not speak English, little Spanish and
            fluent Hindi. I on the other hand spoke little Spanish and little
            hindi. So, there I was trying to communicate in broken Spanish and
            Hindi. But, she was nice to invite me to see her house, told me
            where I could get Indian clothes, invited me to the celebrations
            each evening, for the next days. I felt very happy to see an elder
            person with whom I could relate to, and with whom I could associate
            kindness and spirituality.
          </p>
          <p>
            Then, I left the temple, walked my way down the hill to the bus stop
            to go to Eldorado (https://goo.gl/maps/W0mKA ). I finally found the
            shop Sol de la India (https://goo.gl/maps/Xz2RH ). The shop was big
            with lots of souvenirs, prayer items, clothes, jewellery among
            others. I finally got myself some Indian clothes. I also bought some
            prayer items to do my prayers at home. As I left the shop, it was
            already 5:30 pm. The lady at the temple had said that the prayers
            would start at 7:00 pm. I did not know whether I could go directly
            there, because it was only some 10 minutes distance from bus. Or I
            could go back home, take a shower and come back. Since I was having
            a very bad headache, from having skipped lunch, I decided to take
            the bus home and to come back the next evening for the prayers.
          </p>
          <p>
            I therefore took a bus to Allbrook (https://goo.gl/maps/fxzFK). On
            the way, the bus passed through many poor suburbs, and with my
            painful headache, I felt a little unsafe but at the same time I felt
            the strength of my faith. I felt satisfied of the day. From
            Allbrook, I took another bus to Punta Pacifica
            (https://goo.gl/maps/OrZW5 ), got down at Multiplaza, headed
            straight for the food court, ordered vegetarian pizza, ate it on the
            spot, and sat there until I felt my energy coming back. Then since
            it was already night and unsafe to walk, I took a taxi to my
            apartment, even though it was only a few minutes’ walk away.
          </p>
          <p>That was my experience of Ganesh Chaturthi in Panama.</p>
          <ul>
            <p>Lessons of the Day: </p>
            <li>
              That day I was reminded that once we think of doing something, and
              want to do it, we should always just make the first step no matter
              what. Then we see that all our resistances and possible fears
              disappear as we progress towards achieving what we had set our
              mind to do. And whenever we have some fear, it can be helpful to
              remember of past events where we had similar situations and just
              the fact that finally we had come out of that situation well, will
              give us the right motivation to continue in this current situation
              too.
            </li>
            <li>
              Another lesson of that day was that faith is very important for
              any individual to progress in life, no matter what the source of
              the faith is, whether it is one religion or another, or something
              else that you believe in altogether that keeps us in harmony in
              our life.
            </li>
          </ul>
        </div>
        <Footer />
      </div>
    );
  }
}

export default BlogPostPanamaGanesh;
