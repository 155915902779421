import React, { Component } from "react";
import Navbar from "../headercomponents/navbar.jsx";
import Footer from "../FooterComponents/footer.js";
import Jumbotron from "../JumbotronComponents/jumbotron.jsx";
import "../../App.css";
import { AirbnbLinks } from "../AdditionalComponents/AirbnbLinks";
import BlogLessonsLearnedPast15Years from "../Pages/BlogLessonsLearnedPast15Years";
class HomePage extends Component {
  render() {
    return (
      <div>
        <Navbar />
        <Jumbotron
          title="Travelling Around The World"
          subtitle="Life is a journey"
        />
        <BlogLessonsLearnedPast15Years showArticleOnly="true" />
        {/* <AirbnbPreview /> */}
        <AirbnbLinks />
        <Footer />
      </div>
    );
  }
}

export default HomePage;
