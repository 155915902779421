import React, { Component, useState } from "react";
import { NavItem } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../headercomponents/navbar.css";
import NavbarBlogMenuItems from "./NavbarBlogMenuItems";
import NavbarAbout from "./NavbarAbout";
import NavbarGallery from "./NavbarGallery";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
    this.handleisOpenChange = this.handleisOpenChange.bind(this);
  }

  myFunction() {
    var x = document.getElementById("myTopnav");
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }
  }
  handleisOpenChange() {
    this.setState({
      ...this.state,
      isOpen: !this.state.isOpen,
    });
    console.log(this.state.isOpen);
  }
  render() {
    return (
      <nav class="flex items-center justify-between flex-wrap bg-blue-500 p-6 ">
        <div class="flex items-center flex-shrink-0 text-white mr-6">
          <svg
            class="w-[69px] h-[69px] fill-[#ffffff]"
            viewBox="0 0 384 512"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M19.7 34.5c16.3-6.8 35 .9 41.8 17.2L192 364.8 322.5 51.7c6.8-16.3 25.5-24 41.8-17.2s24 25.5 17.2 41.8l-160 384c-5 11.9-16.6 19.7-29.5 19.7s-24.6-7.8-29.5-19.7L2.5 76.3c-6.8-16.3 .9-35 17.2-41.8z"></path>
          </svg>

          <Link className="navbar-brand text-white" to="/">
            Enriching Travels
          </Link>
        </div>
        {/*       <div className="block lg:hidden">
          <button
            id="menu"
            class="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white"
          >
            <svg
              class="fill-current h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
 */}
        <div class=" text-sm lg:flex-grow">
          <div class=" mr-6 relative inline-block text-left">
            <NavbarBlogMenuItems />
          </div>

          <div class=" mr-6 relative inline-block text-left">
            <NavbarGallery />
          </div>
          <div class=" mr-6 relative inline-block text-left">
            <NavbarAbout />
          </div>
          {/*           <div class=" mr-6 relative inline-block text-left">
            <a
              href="#"
              class="inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-teal-500 hover:bg-white mt-4 lg:mt-0"
            >
              Contact
            </a>
          </div> */}
          <div></div>
        </div>
      </nav>
    );
  }
}
export default Navbar;
