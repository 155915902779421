import React, { Component } from "react";
import Navbar from "../headercomponents/navbar.jsx";
import Footer from "../FooterComponents/footer.js";
import Jumbotron from "../JumbotronComponents/jumbotron.jsx";
class BlogPostClosing2018 extends Component {
  render() {
    return (
      <div>
        <Navbar />
        <Jumbotron
          title="Trip To Close 2018"
          subtitle="A trip for a new beginning"
        />
        <div className="container">
          Day - 7: Saturday 15/12/2018
          <br />
          It was the first time that I was doing so much preparation for a trip.
          Most of my work trips have been haphazard, last minute.
          <br />
          Some retrospective to illustrate this statement…
          <br />
          Last year in 2017, my trip to Denmark was confirmed the day before the
          flight. I just got confirmation on the Friday, I remember I was in the
          gym, and I was not even sure whether I was given approval to go, I
          even asked my friend to read information to confirm it.
          <br />
          So, I went home to start packing.. The next day, on Saturday, we went
          shopping to buy stuffs rapidly and packed my stuffs as quickly as
          possible.
          <br />
          In the evening, I remember my relatives had to help me to close the
          suitcase… They managed to close it but I realised when I arrived that
          they had left one slipper out of the suitcase such that I had to buy
          another pair there. The flight was Sunday morning at around 8:30 am if
          I remember well such that we set out of the home at 5:30 am. I
          remember I was so stressed out and with hindsight, I feel so grateful
          to have such a family who is always there when I need them. All my
          siblings and family had come to stay the night so that we could go
          together the next early morning to the airport. I was not even allowed
          to drive because I looked very tense.
          <br />
          But all went fine at the airport and I managed to have a good flight.
          The overnight journey in trains getting from Cologne, all the way
          across Germany, to Vejle Denmark, lasting almost 18 hours, is another
          adventure, worth a separate blog post by itself :-)
          <br />
          During that stay in Denmark, I went for a short trip to Italy.. This
          was also decided and confirmed last minute. I was not very keen to go,
          considering that right after the trip, I was supposed to sit for some
          exams.... So again, it was a ticket which was booked 2 days before the
          flight. This time, since I already had only 1 suitcase of things, I
          had less stuff to choose from, to bring.. Still I had to pack all my
          stuffs which I had bought there to Denmark and send them to the
          colleague’s house because I was not going to return to that apartment
          again. I had to try to prepare as light a suitcase as possible because
          the trip to Italy was supposed to be only for 3 weeks. So, again, the
          packing last minute was a bit tense. With some help, I booked a flight
          to Rome and return ticket from Milan. I managed to get one
          accomodation in Rome. I had decided that from there, I can decide
          whether I would want to book other accommodations to visit other
          places.
          <br />
          I would not say that these preparations were stressful because I guess
          I am very used to having to operate under that level of pressure… When
          I think of it now, it is a choice that I made. We could choose not to
          do certain things but if we do, then we are bound to go through some
          things .. Does it make sense?
          <br />
          Anyway, again, all went fine… of course, there were some mishaps when
          I arrived at my hostel in Rome… but I guess, in retrospective, we see
          these as adventures and lessons and it no longer feels stressful… That
          is what is so strangely amazing about life and human nature… We
          remember past events but we can no longer feel those feelings related
          to these events again and this fact contributes to rarely feeling
          regretful about the past..
          <br />
          End of the retrospective ...
          <br />
          <br />
          <br />
          Now it is Day - 1. For this next trip that I am about to take, I had
          to plan well ahead because I had to plan for the climate,
          accommodations which require lots of
          communication/negotiation/organisation, and time restriction,
          considering that I had a heavy schedule in general… I had so many todo
          lists, checklists, calendar events to manage all… But I think, all
          these helped because here I am Friday night, lying on bed and getting
          time to complete this article. The suitcase is ready. The house has
          been more or less tidied and cleaned. The christmas tree has been
          decorated. All necessary stuffs like shoes, winter jacket, medicine
          etc have been bought. Vaccination has been done. The accommodations
          have been “booked”.
          <br />
          <br />
          <img
            src={require("../../images/christmastree1.JPG")}
            width="200"
            height="200"
            className="rotateimg180"
            alt="christmastree1"
          />
          <img
            src={require("../../images/suitcase1.jpg")}
            width="200"
            height="200"
            className="rotateimg180"
            alt="suitcase1"
          />
          <br />
          <br />
          My office work has been completed. Ok, not completely because I found
          out this week only that there was another part which I needed to do.
          Butsince the deadline is end of January and the actual delivery is
          expected for mid February, I still have time to finish what I have
          started this week, when I resumed work in January.
          <br />
          The reason for writing this blog post before the trip is to get
          irrevocable faith for another leap… It is another task which I would
          like to strikethrough before my flight.
          <br />
          The trip is going to be the ultimate challenge for the year 2018. To
          push through and end 2018 in the way that will make me stronger
          physically and emotionally :-)
          <br />
          I had hesitated so long before deciding to go ahead with this trip,
          some kind of old dream ….
          <br />
          The aim of this post is to really write about it in full faith and
          confidence even though what is waiting is totally unknown, in terms of
          destination, in terms of climate, in terms of expectations, in terms
          of outcome… I think that this year, I have really lived and gone
          through each day in full consciousness. Though the year has gone very
          fast for sure, I can almost recall each month of that year, if not
          each week or even day! This year has brought a whole panoply of
          challenges, which I think I was able to meet face to face. What I
          learned is that I just had to take one step ahead and not stop ... We
          can slow down when we do feel tired, but we should not quit… It is
          fine to take a break when we are tired but it is not fine to stop. A
          lot of events unfolded surprisingly well…, all by hard work and not
          giving up.
          <br />
          So, I said that I should take a chance and go for one dream this year
          itself, no matter the situation/circumstances, especially in such
          situation/circumstances, no matter on my own, especially on my own:-),
          no matter if I have a very tight budget, especially on a very tight
          budget… What I have learned this year, is that no matter the
          restrictions and constraints, if we continue and don’t give up, sooner
          or later, doors will open, things will pave their own way.. .that’s
          how life is… But it’s hard to believe in that in these situations..
          That’s what this trip is all about : venturing out and trusting all
          will be fine.
          <br />
          Usually we don’t talk about uncertain events when they are happening,
          but talk about them when we have succeeded in overcoming them… This
          time, I want to talk about this trip before it happens, and then see
          how it has turned out afterwards… This trip is important in terms of
          the goals that are related to it… I am curious to find out how it will
          ufold…
          <br />
          I have made a lot of efforts in order to be able to be ready for it.
          For example, I sacrificed gym, zumba classes and badminton sessions
          for a week or so, in order to free myself, for which I am not very
          proud and happy. But I had to do it to be able to be ready. I guess
          that this is one lesson that I have finally learned. You have same
          amount of time, all the time. If you are going to kill yourself in the
          gym and not get enough energy afterwards to do any preparation or
          work, then it is better to workout less or not at all a few days, just
          so that you finish some other important things. You can always try to
          see whether you can fit in everything but if you see that you are not
          able to, then you should not put stress on yourself. In urgent
          situations, don’t continue to try to be superwoman when you see that
          it is not working, either get help or stop less urgent tasks to make
          time for the important urgent ones.. By completing urgent tasks, you
          get feeling of relief and motivation to continue to push through.
          <br />
          Well, I have talked about it… Let us see how much I walk the talk and
          survive this trip! Oh did I mention that there is a storm (cyclone
          warning Class II) in the region currently and there is some chance
          that the tomorrow night’s flight is cancelled… In fact, when I was
          returning from office, the weather was very bad , with heavy rainfall
          and some winds… Now, the weather seems to have calmed down but we have
          to wait for tomorrow’s weather bulletin to get any information about
          the flight… Haha, the adventure starts well. Let us see how well I
          will fare… Let us see how strong I am truly.. Let us see how
          resourceful I can get when problems happen, Let us see how I will deal
          with those Let us see how much risks I am willing to take in life, Let
          us see how much guts I still have … Will I freeze in front of these
          situations, let’s see ….
        </div>
        <Footer />
      </div>
    );
  }
}

export default BlogPostClosing2018;
