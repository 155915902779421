import React, { Component } from "react";
import Navbar from "../headercomponents/navbar.jsx";
import Footer from "../FooterComponents/footer.js";
import Jumbotron from "../JumbotronComponents/jumbotron.jsx";
class About extends Component {
  render() {
    return (
      <div>
        <Navbar />
        <Jumbotron title="About Me" subtitle="I am simply me" />
        <div className="container">
          <h2>Why I started this blog?</h2>
          <p>
            I left my country for the first time, on the 24th May 2014, to start
            a long trip for work. Though, I did think of sharing this journey
            through a blog, when I was making my preparation for the trip, these
            past months have been very hectic. And it is only now, early
            September that I am finally making time to start one. I saw this
            blog project as a new hobby to allow myself to relax while I
            canalize some of my thoughts into something very creative.
            Therefore, the idea of this blog is to share the experience of this
            journey, to analyse the past and current happenings of the trip from
            various perspectives and take out the useful lessons out of them.
            Since I have recently embarked into project management at work, I
            thought why not try to view each event during my travel, whether
            professional or personal, from a management perspective and try to
            see whether I had been applying all management and relationship
            skills to the situation. If not, how I could improve or what would
            be the lessons learnt from the situations to make similar situations
            better in future. I will also try to give travelling tips that I
            have myself learnt from my own experience and from others.{" "}
          </p>
        </div>
        <Footer />
      </div>
    );
  }
}

export default About;
