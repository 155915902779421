import React, { Component } from "react";
import Navbar from "../headercomponents/navbar.jsx";
import Footer from "../FooterComponents/footer.js";
import Jumbotron from "../JumbotronComponents/jumbotron.jsx";
class BlogPostSingapore extends Component {
  render() {
    return (
      <div>
        <Navbar />
        <Jumbotron title="Singapore 2015" subtitle="Expect the Unexpected" />
        <div className="container">
          Flying to Singapore came up as a surprise early January 2015. As soon
          as we stepped into 2015, I was wondering whether what should be my
          personal plans for the year. Then, this work trip to Singapore came as
          a compass. At this point in life, unlike other people who already have
          found their destination in life, I should admit that I still have not
          found mine. I just have the big goals about life in mind, but often
          things as they come, trying and hoping to make them into
          opportunities. The decision to go to Singapore and hold back my
          personal life for another 3 months was just another example where I
          just take another chance, fully aware that the clock was ticking and
          every day counted for what the personal goals I had in life. Maybe I
          needed to be worried that I still did not have something to hold on
          to, like a settled life on which to base my decisions, but the fact
          remains that that was the status of things. This was exactly the
          reflection in mind when I boarded the plane that Friday night of the
          6th February 2015. But, as I sat down and fastened my seat belt, I
          decided that whatever the decision, it was already sealed, I now
          needed to make the most of it. The flights were already indicative
          that Singapore was going to be like, bitterly, sourly sweet☺. First
          from Mauritius to Malaysia, I was happily surprised to see that the
          plane was kind of empty, so my neighbor went to another seat, leaving
          his seat, additional pillow and blanket for myself; I was thus able to
          enjoy a movie cozily and use the whole seat as a small bed to sleep
          peacefully☺ However hours later, when I woke up, I realized that my
          connection flight was MH611…. Oh MH??? Like MH370
          (http://www.bbc.com/news/world-asia-26492748) or MH17
          (http://www.bbc.com/news/world-europe-31719040 )??? It never occurred
          to me that I would be flying with Malaysia Airlines because my
          e-ticket only mentioned Air Mauritius, but that was because the second
          flight was a code sharing flight
          (https://www.aeroflot.ru/cms/en/about/flight )! Thus, this was the
          trailer of what the Singapore trip will turn out to be ☺ Expect the
          Unexpected or Unexpect the Expected ☺ My apartment was in Farrer Park
          (https://goo.gl/maps/b1VZH) between Race Course Road
          (https://goo.gl/maps/7N6MJ ) and Serangoon Road
          (https://goo.gl/maps/zkNE2 ). I was told that it will be near Little
          India, where there would be community of Indians. Having not bothered
          to make any research before making the trip, I never thought that
          Singapore had such huge population of Indians, both original from
          India and several generations of Singapore-born citizens of Indian
          Origins. On the first Sunday, I was taken aback by the huge number of
          Indian workers along Serangoon Road, I was told that Sunday was the
          only day when the workers were free and therefore, went out to do
          shopping and socialize. The Indian shops and noise created by the
          crowd were amazing; I almost thought that I might have landed in
          India! But to be honest, I liked it because I did not feel alone,
          there were people (many men) everywhere, sitting around, walking,
          talking, looking at me… But most importantly, despite such crowds,
          which at first sight, looked intimidating and daunting, I felt
          surprisingly safe. People made sure that they were never staring at
          you and managed never to bump into you! Among the crowds, there were
          many couples, many with kids, many families including elderly. It was
          an ordinary highly residential area, which contrasted with the
          preconceived image of the perfectly clean and efficient Singapore that
          I had heard about, which was perfect for me because I was able to
          blend into that area easily, even single. Another nicer example of
          Singapore’s nice surprises was my second week in Singapore, the
          narration of which will come in another post, where I will describe
          how I was lucky to be part of both celebrations of Chinese New Year
          (Expected) and Mahashivratree (Unexpected!!) .
          <ul>
            <li className="item">
              Simply trust that everything is going to be fine. Of course, we
              should not blindly trust. Take time to consider all the aspects of
              things and conditions before you take a decision. Try to change
              the conditions if you feel that they negatively impact you. But if
              you cannot, trust your gut feeling and go for what seems the
              correct thing to do. But be brave enough to take a decision,
              whichever side you flip to.{" "}
            </li>
            <li className="item">
              There are always two sides of a coin. It all depends how we look
              at it. We should always try to look at the positive sides of
              things.{" "}
            </li>
            <li className="item">
              There is a right time for everything; do not rush; just go with
              the current and enjoy the ride.{" "}
            </li>
            <li className="item">
              Be thankful for everything and everyone that come your way.{" "}
            </li>
          </ul>
        </div>
        <Footer />
      </div>
    );
  }
}

export default BlogPostSingapore;
