import React from "react";
import Navbar from "../headercomponents/navbar.jsx";
import Footer from "../FooterComponents/footer.js";
import Jumbotron from "../JumbotronComponents/jumbotron.jsx";

export const EnrichingTravelsReloaded = () => {
  return (
    <div>
      <Navbar />
      <Jumbotron
        title="EnrichingTravels - RELOADED"
        subtitle="The Start of React"
      />
      <div className="container">
        <h2>EnrichingTravels - RELOADED</h2>
        <p>
          EnrichingTravels reborn on the first day of World Cup 2018 - Four
          Years after World Cup 2014 in Brazil, at the time of which I was on a
          short work trip in Panama.{" "}
        </p>

        <p>
          As I constantly hear about Russia on the Radio while I do my daily one
          hour commute, I have been feeling nostalgic about those past
          adventures. At that time, it was such an special event considering I
          was in so close to the host country. So, as the open ceremony was
          taking place and everybody went home to watch the opening match Russia
          v/s Saudi Arabia, I went to search for any backups that I could get
          and put this blog back up. I was able to land on the blog articles
          only
        </p>

        <p>
          It is an initial publication of the blog. More work needs to be done
          on it.
        </p>

        <p>
          {" "}
          Technical Parenthesis: For the moment, the blog is a simple React.js
          Application hosted as Serverless application on AWS using S3, Lambda
          Function and API Gateway{" "}
        </p>
      </div>
      <Footer />
    </div>
  );
};
