import React, { Component } from "react";
import Navbar from "../headercomponents/navbar.jsx";
import Footer from "../FooterComponents/footer.js";
import Jumbotron from "../JumbotronComponents/jumbotron.jsx";
class BlogLessonsLearnedPast15Years extends Component {
  render() {
    return (
      <div>
        {this.props.showArticleOnly ? (
          <h2>What I have Learned In The Last 15 Years</h2>
        ) : (
          <div>
            <Navbar />
            <Jumbotron
              title="What I have Learned In The Last 15 Years"
              subtitle="A LOT!"
              className="denmark"
            />
          </div>
        )}

        <div className="container mx-auto bg-stone-50">
          <section className="min-h-screen items-center" id="tips">
            <div>
              <div className="flex items-center grid grid-cols-2 gap-2">
                <h1 className="text-2xl mt-2 text-right mr-3  text-right mr-3 ">
                  1. Stay Curious
                </h1>
                <div>
                  <img
                    src="https://picsum.photos/700/800?random=1"
                    className="rounded-t rounded-b-md h-28 w-56 "
                    alt="img"
                  />
                </div>
              </div>
              <div>
                <p className="pb-4 text-justify mt-3">
                  Knowledge is power. By staying curious you will be motivated
                  to continually learn and improve your personal and technical
                  skills required in your job and life in general. There is less
                  chance for you to get fooled when you know enough about the
                  matter at hand. As you progress in life and start to have
                  other priorities, develop quick ways to stay in touch and
                  continue to learn things fast e.g. through the news, podcasts,
                  meet-ups, courses, blogs etc{" "}
                  <a
                    className="text-blue-900 font-bold"
                    target="_blank"
                    href="https://www.amazon.de/b?_encoding=UTF8&tag=enrichingtr06-21&linkCode=ur2&linkId=d09c764b42b617b75b5bd5ab4ad7fc83&camp=1638&creative=6742&node=54071011"
                  >
                    books
                  </a>{" "}
                  etc.{" "}
                </p>
                <p className="pb-4 text-justify mt-3 italic">
                  I can remember that when I was a child, some people would
                  jokingly tell my mother that I asked too many questions. In
                  those days, it felt like perhaps I was different compared to
                  other kids. Now when I reflect back to these moments, I am
                  happy that my mom would smile but she never stopped me from
                  asking questions. Today I still question a lot of things!
                </p>
                <p className="pb-4 text-justify mt-1 italic">
                  So, do not hesitate to ask a question When you do not
                  understand something or do not agree with something or just
                  want to learn more. Stay curious as a child.
                </p>
                <p className="pb-4  text-justify">
                  Knowledge can also help you become more self-reliant and live
                  a more prosperous life. For example, learning how to do basic
                  electric fittings, plumbing , carpentry and other household
                  work can save you money and let you develop creative hobbies.
                  Learning about the different laws which affect you (renting,
                  work) in the country where you live can help you avoid a lot
                  of stress and be more pre-emptive. Learning details about
                  taxes, insurances and insurances can help ensure better
                  standards of living for the present and future .
                </p>
              </div>
              <div className="flex items-center grid grid-cols-2 gap-2">
                <h1 className="text-2xl mt-5 text-right mr-3 ">
                  2. Timing is important
                </h1>
                <div>
                  <img
                    src="https://picsum.photos/700/800?random=2"
                    className="rounded-t-md h-28 w-56 rounded-b-md  "
                    alt="img"
                  />
                </div>
              </div>
              <p className="pb-4 text-justify mt-3">
                Be patient. Do not always act spontaneously; acting
                spontaneously brings excitement and makes personal life
                interesting. However, in both personal and professional fields
                sometimes taking time before speaking or acting can be super
                important. Be a good judge of when spontaneity is necessary.
                Sometimes thinking and waiting too long could also be
                counterproductive;” bus pou quitte toi pou aller; the bus will
                leave you” as we say in my local creole language. It means that
                almost everything has an expiry date. If you don’t work on your
                ideas fast enough, they can quickly become obsolete later or
                others could already get the same ideas and work on them before
                you.
              </p>
              <p className="pb-4 text-justify mt-3 italic">
                I used to like to solve problems quickly in order to be
                proactive in tackling them. While it could be the right strategy
                in my profession, in other situations, it can also be worth it
                to wait, observe and then take action especially when dealing
                with people's emotions.
              </p>
            </div>

            <div>
              <div className="flex items-center grid grid-cols-2 gap-2">
                <h1 className="text-2xl mt-5 text-right mr-3 ">
                  3. Know when honesty is required
                </h1>
                <div>
                  <img
                    src="https://picsum.photos/700/800?random=3"
                    className="rounded-t-md h-28 w-56 rounded-b-md  "
                    alt="img"
                  />
                </div>
              </div>
              <p className="pb-4 text-justify mt-3">
                I learned very late in life that it is sometimes better not to
                say the truth and be honest if it can keep peace and maintain a
                relationship.
              </p>

              <p className="pb-4 text-justify mt-3 italic">
                If a friend asks you how she is looking in her dress, there is
                really no need to tell her that she is looking fat in it, unless
                she is trying it at a shop. So, sometimes honesty can be spared
                if it will not serve any purpose other than hurt the feelings of
                the other person. Moreover, there is a time, a place and a way
                to be honest or frank with others. "Avoid being blunt and speak
                with Tact" is one of my biggest lessons in life."
              </p>
            </div>
            <div className="flex items-center grid grid-cols-2 gap-2">
              <h1 className="text-2xl mt-5 text-right mr-3 ">
                4. Know when being right is required
              </h1>
              <div>
                <img
                  src="https://picsum.photos/700/800?random=4"
                  className="rounded-t-md h-28 w-56 rounded-b-md  "
                  alt="img"
                />
              </div>
            </div>
            <p className="pb-4 text-justify mt-3">
              The same applies for being right. You can think you are right and
              that you are the one person in the room who has the most common
              sense around what is being discussed. However, at the end of the
              day, being right and proving a point is not what matters the most.
              Especially if it can hurt the relationship with colleagues,
              customers, friends, relatives or anyone in the room. In such
              situations, silence is more important than proving a point.
            </p>
            <p className="pb-4 text-justify mt-3 italic">
              I used to think things are either black or white; bad or good,
              wrong or right. I later realised that things were never straight
              forward; people were never just bad or good; there were so many
              factors involved. Indeed, Life is not black or white... So
              starting to change my mindset to embrace that helped me to be more
              tolerant of others and to build better relationships.
            </p>
            <div className="flex items-center grid grid-cols-2 gap-2">
              <h1 className="text-2xl mt-5 text-right mr-3 ">
                5. Stay True and Authentic
              </h1>
              <div>
                <img
                  src="https://picsum.photos/700/800?random=5"
                  className="rounded-t-md h-28 w-56 rounded-b-md  "
                  alt="img"
                />
              </div>
            </div>
            <p className="pb-4 text-justify mt-3">
              However, do not allow overthinking situations and keeping thoughts
              to yourself make you cynical, unauthentic and even calculating. Be
              your own authentic self and continue to find the right balance in
              every situation. With time and experience, it gets easier to be
              yourself. I like the idea that there is only one you ; you don’t
              need to be anyone else. It took me a while to understand why I was
              attractive to many people ; I was raw and I realised that people
              were cautious of me every time I tried to change; they could
              perceive the change. Also, staying true to yourself does not make
              you necessarily vulnerable if that is a concern.
            </p>
            <p className="pb-4 text-justify mt-3 italic">
              At some point, in my life, I realised , that I was not exactly
              like others in my environment; I was more stubborn, refusing to be
              a follower and to blend in. There was even a time I thought I was
              weird. A part of me wanted to change but a part refused to change,
              well just out of stubbornness! People who are like that, will be
              able to relate to this... Well, it took me a long time to realise
              that this "stubbornness" was actually actually "having grit",
              which is a positive trait of character! I also realised that the
              reason I had been attractive to friends and people of various
              spheres of my life was exactly because I was different and
              authentic. So do not change just to be like others! Change only to
              become the best version of yourself!
            </p>
            <div className="flex items-center grid grid-cols-2 gap-2">
              <h1 className="text-2xl mt-5 text-right mr-3 ">
                6. Pause Before you Speak
              </h1>
              <div>
                <img
                  src="https://picsum.photos/700/800?random=6"
                  className="rounded-t-md h-28 w-56 rounded-b-md  "
                  alt="img"
                />
              </div>
            </div>
            <p className="pb-4 text-justify mt-3">
              There is a big difference between thinking something and saying
              something ; once said many things cannot be reversed. Once written
              somewhere on the Internet, it stays there somehow. The results can
              be devastatingly long lasting and irreversible. Also the mind has
              funny ways to think about things and if you do not control your
              thoughts, they can take you anywhere, in the deepest dungeons. So
              while we cannot always control our thoughts, it is good to remind
              ourselves that what we thought may not be true. e.g. when I was
              younger, I used to get anxious about what people were thinking
              about me and these thoughts negatively influenced my behaviour
              towards them, which in turn changed their own behaviour towards me
              such that in the end what I had feared in my head became true.
              Crazy huh? That’s why the power of attraction is real, you attract
              what you think about!
            </p>
            <div className="flex items-center grid grid-cols-2 gap-2">
              <h1 className="text-2xl mt-5 text-right mr-3 ">
                7. Keep your value, power and strength
              </h1>
              <div>
                <img
                  src="https://picsum.photos/700/800?random=7"
                  className="rounded-t-md h-28 w-56 rounded-b-md  "
                  alt="img"
                />
              </div>
            </div>
            <p className="pb-4 text-justify mt-3">
              Know what you bring to the table; do not undervalue yourself ;
              there’s enough people around who will do that. Don’t give so much
              power to another person that they can destroy you. One way to keep
              power is to give less information about yourself; we say knowledge
              is power ; so the less people know about your thoughts and plans,
              the less power they will have over you and they will be lesser
              threats to you. Once someone told me "I know you more than you
              know yourself"; it was true; if they use it on purpose or
              accidentally against you, it can hurt you and make you hit rock
              bottom.
            </p>
            <div className="flex items-center grid grid-cols-2 gap-2">
              <h1 className="text-2xl mt-5 text-right mr-3 ">
                8. Change is Permanent
              </h1>
              <div>
                <img
                  src="https://picsum.photos/700/800?random=8"
                  className="rounded-t-md h-28 w-56 rounded-b-md  "
                  alt="img"
                />
              </div>
            </div>
            <p className="pb-4 text-justify mt-3">
              People change quickly, circumstances change quickly, Technology
              changes even more rapidly. Be flexible; learn to adapt and enjoy
              the changes. Therefore do not hesitate to change yourself as long
              as each change brings a better version of yourself.
            </p>
            <div className="flex items-center grid grid-cols-2 gap-2">
              <h1 className="text-2xl mt-5 text-right mr-3 ">
                9. Build On Healthy Emotional Attachment
              </h1>
              <div>
                <img
                  src="https://picsum.photos/700/800?random=9"
                  className="rounded-t-md h-28 w-56 rounded-b-md  "
                  alt="img"
                />
              </div>
            </div>
            <p className="pb-4 text-justify mt-3">
              Do not get attached to things, places or even too much to people
              because everything changes. Of course we do not want to be a robot
              without feelings, but having that perspective in life will help
              you get a better outlook of things or get you back on track when
              you get faced with overwhelming changes in your environment.
            </p>
            <div className="flex items-center grid grid-cols-2 gap-2">
              <h1 className="text-2xl mt-5 text-right mr-3 ">
                10. Break Out Vicious Cycles & Negative Patterns
              </h1>
              <div>
                <img
                  src="https://picsum.photos/700/800?random=10"
                  className="rounded-t-md h-28 w-56 rounded-b-md  "
                  alt="img"
                />
              </div>
            </div>
            <p className="pb-4 text-justify mt-3">
              Look for negative patterns in your behaviour; think and take
              actions to break them. Else these will always hinder your progress
              and growth by making you repeat the same mistakes. The saying that
              "doing the same thing and expecting a different outcome is crazy"
              is so true. While we stay in the same vicious cycles, we make the
              same mistakes over and over again, making us frustrated not only
              with the impact of the mistake and feeling miserable when we
              realise that we did not learn from the past. Be careful that
              changing the environment; cities, countries, jobs, companies,
              colleagues, partners,friends do not necessarily mean that we are
              breaking out of vicious cycles; we might still repeat past
              mistakes! In fact, getting out of a vicious cycle really involves
              becoming conscious of the repeating situation and taking a
              conscious decision to take different actions. In fact, breaking
              patterns can be quite difficult but it is so uplifting once we
              manage to do it the first time.
            </p>
            <div className="flex items-center grid grid-cols-2 gap-2">
              <h1 className="text-2xl mt-5 text-right mr-3 ">
                11. Know your limitations
              </h1>
              <div>
                <img
                  src="https://picsum.photos/700/800?random=11"
                  className="rounded-t-md h-28 w-56 rounded-b-md  "
                  alt="img"
                />
              </div>
            </div>
            <p className="pb-4 text-justify mt-3">
              Be mindful of your limits and when you need help from others. We
              may need help to improve our knowledge and understanding of
              different areas of our Job through a
              mentor.https://ideas.ted.com/the-5-types-of-mentors-you-need-in-your-life/amp/
              We may need help to get clarity on our goals, career paths through
              a career coach. We may need help to understand our life and
              emotions through other specialists like a life coach or a
              psychologist.
            </p>
            <div className="flex items-center grid grid-cols-2 gap-2">
              <h1 className="text-2xl mt-5 text-right mr-3 ">
                12. Build your own support system
              </h1>
              <div>
                <img
                  src="https://picsum.photos/700/800?random=12"
                  className="rounded-t-md h-28 w-56 rounded-b-md  "
                  alt="img"
                />
              </div>
            </div>
            <p className="pb-4 text-justify mt-3">
              Sometimes you just need to be around people who can cheer you up
              and be your cheerleaders like some friends and relatives. Be sure
              to have such people whom you can trust. But be aware that nobody
              can be there for you for life and forever. Be prepared that the
              same close ones might always stay close to you or you close to
              them. The only person who will always stay with you is yourself.
              Therefore build a good connection with your own self. Be your own
              cheerleader ; don’t shoot yourself in the feet. Learn to avoid
              negative self-talk. Be kind, understanding and supportive to
              yourself.
            </p>
            <div className="flex items-center grid grid-cols-2 gap-2">
              <h1 className="text-2xl mt-5 text-right mr-3 ">
                13. Learn to prioritize
              </h1>
              <div>
                <img
                  src="https://picsum.photos/700/800?random=13"
                  className="rounded-t-md h-28 w-56 rounded-b-md  "
                  alt="img"
                />
              </div>
            </div>
            <p className="pb-4 text-justify mt-3">
              Know what is important in your life ; priorities change. And that
              is ok. Revisiting and Re-evaluating your priorities regularly and
              taking decisions based on those are super important to help you
              reach your goals. Having a purpose in life is what will keep you
              going in life. Make sure that you prioritise what is good for your
              own well being.
            </p>
            <div className="flex items-center grid grid-cols-2 gap-2">
              <h1 className="text-2xl mt-5 text-right mr-3 ">
                14. What others think of you or what others think, is really
                none of your business
              </h1>
              <div>
                <img
                  src="https://picsum.photos/700/800?random=14"
                  className="rounded-t-md h-28 w-56 rounded-b-md  "
                  alt="img"
                />
              </div>
            </div>
            <p className="pb-4 text-justify mt-3">
              Once you build that thick skin, the confidence and comfort that it
              brings will give you wings to fly. What you think of yourself is
              what will pull you down in a spiral ! If you start to think what
              others are thinking about you and you start to believe what you
              think they are thinking about you, then you are digging your own
              hole to fall into it. It’s not easy to build that mindset and
              certainly not easy to keep that strong mindset all the time;
              however we need to work on those muscles continuously. And
              whenever you feel the confidence is at a high, use it to soar
              higher and further! Ride the waves 🌊
            </p>
            <p className="pb-4 text-justify mt-3 italic">
              People will always have something to say; it is too late, too
              early, you are too thin, too fat etc. Just go ahead with your
              plans anyway.
            </p>
            <div className="flex items-center grid grid-cols-2 gap-2">
              <h1 className="text-2xl mt-5 text-right mr-3 ">
                15. Always have dreams, goals and plans
              </h1>
              <div>
                <img
                  src="https://picsum.photos/700/800?random=15"
                  className="rounded-t-md h-28 w-56 rounded-b-md  "
                  alt="img"
                />
              </div>
            </div>
            <p className="pb-4 text-justify mt-3">
              Failing to Plan is Planning to fail is just a strong saying.
              Having goals just helps keep you in control of the direction your
              life is going both personally and professionally. Sometimes it’s
              good to have just Plan A so that you do all you need to make Plan
              A works because there’s no plan B. In other times it’s good to
              have options 🙂
            </p>
            <div className="flex items-center grid grid-cols-2 gap-2">
              <h1 className="text-2xl mt-5 text-right mr-3 ">
                16. Don't take things or life too seriously
              </h1>
              <div>
                <img
                  src="https://picsum.photos/700/800?random=16"
                  className="rounded-t-md h-28 w-56 rounded-b-md  "
                  alt="img"
                />
              </div>
            </div>
            <p className="pb-4 text-justify mt-3">
              I learned that having a nice simple perspective of life will keep
              you grounded on what matters every time. I learned a little late
              that it’s ok to laugh no matter the circumstances. A few things
              can be wrong at any time in your life. Not everything will be
              wrong all the time.. Keep perspective of the situation.
            </p>
            <div className="flex items-center grid grid-cols-2 gap-2">
              <h1 className="text-2xl mt-5 text-right mr-3 ">
                17. Don’t doubt yourself
              </h1>
              <div>
                <img
                  src="https://picsum.photos/700/800?random=17"
                  className="rounded-t-md h-28 w-56 rounded-b-md  "
                  alt="img"
                />
              </div>
            </div>
            <p className="pb-4 text-justify mt-3">
              Do not doubt yourself because there will already be enough people
              doubting you out there. Treat imposter syndrome early on. Get help
              by talking about any confidence issues you may have about your
              abilities and performance early on. Look for a coach or a mentor
              etc Take active actions to fix such problems Don’t sell yourself
              short no matter the relationship. Know what you bring to the
              table, be proud and confident of that and be prepared to leave the
              table if what you bring is not valued.
            </p>
            <div className="flex items-center grid grid-cols-2 gap-2">
              <h1 className="text-2xl mt-5 text-right mr-3 ">18. Be strong</h1>
              <div>
                <img
                  src="https://picsum.photos/700/800?random=18"
                  className="rounded-t-md h-28 w-56 rounded-b-md  "
                  alt="img"
                />
              </div>
            </div>
            <p className="pb-4 text-justify mt-3">
              The workplace will not be a playground. Outside of work might not
              always be a long calm river either.. Learn to navigate the waters.
              Take it as a fun activity. Observe people’s behaviour and attitude
              and act accordingly. Always try to stay nice. Don’t let
              circumstances or the past experience make you bitter. Do not be
              afraid of people’s reaction. Be yourself always. Trust in your own
              ethics; it always works out well in the long run. Be like a
              coconut; strong outside but still soft and healthily sweet inside.
            </p>
            <div className="flex items-center grid grid-cols-2 gap-2">
              <h1 className="text-2xl mt-5 text-right mr-3 ">
                19. Your life Is Your Own responsibility
              </h1>
              <div>
                <img
                  src="https://picsum.photos/700/800?random=19"
                  className="rounded-t-md h-28 w-56 rounded-b-md  "
                  alt="img"
                />
              </div>
            </div>
            <p className="pb-4 text-justify mt-3">
              No one is coming to save you; your life is your own
              responsibility. Do not expect that others will help sort things
              for you; most people come into your life for a reason, a season;
              do not expect them to hang around and help you all the time. You
              will get just enough help as you need from the universe just as
              you will get just as many problems as you can handle. So the
              sooner we understand this the better. It’s up to us to get your
              shit together every time. You get sympathy from people just for
              some time; at some point we need to get rid of the victim
              mentality; it does not get you far.
            </p>
            <div className="flex items-center grid grid-cols-2 gap-2">
              <h1 className="text-2xl mt-5 text-right mr-3  ">
                20. Your Health is Most Precious
              </h1>
              <div>
                <img
                  src="https://picsum.photos/700/800?random=20"
                  className="rounded-t-md h-28 w-56 rounded-b-md  "
                  alt="img"
                />
              </div>
            </div>
            <p className="pb-4 text-justify mt-3">
              Take Care Of Your Body ; It will help to take care of the rest !
              Do some{" "}
              <a
                className="text-blue-900 font-bold"
                target="_blank"
                href="https://www.amazon.de/b?_encoding=UTF8&tag=enrichingtr06-21&linkCode=ur2&linkId=2b97e7897e614a3894d4587227f4f2b2&camp=1638&creative=6742&node=16435051"
              >
                sports
              </a>{" "}
              regularly. Eat healthy whenever possible! A strong body makes a
              strong mind and a strong mind & body will get you through just
              anything in life!
            </p>
            <p className="pb-4 text-justify mt-3">
              There are times when you might be less motivated to follow a diet
              or do sports etc. Do not get discouraged. Get right back to it
              later. Beware that diets can be double edged and create more
              stress and mental problems where you get more urges to eat
              unhealthy. Beware of that.
            </p>
            <div className="flex items-center grid grid-cols-2 gap-2">
              <h1 className="text-2xl mt-5 text-right mr-3 ">
                21. Life Is Short
              </h1>
              <div>
                <img
                  src="https://picsum.photos/700/800?random=21"
                  className="rounded-t-md h-28 w-56 rounded-b-md  "
                  alt="img"
                />
              </div>
            </div>
            <p className="pb-4 text-justify mt-3">
              In the end life goes quickly. Make the most of what life offers❣️.
              Don’t waste time. Dream, learn, discover, explore, love 💕 Be kind
              💕
            </p>
          </section>
        </div>
        {this.props.showArticleOnly ? <div></div> : <Footer />}
      </div>
    );
  }
}

export default BlogLessonsLearnedPast15Years;
