import React from "react";

export const AirbnbLinks = () => {
  return (
    <div>
      <div className="container">
        <h5>Book Your Stay in Ebene Cybercity Mauritius</h5>
        <ul className="airbnbLinks">
          {" "}
          <li>
            {" "}
            <a
              href="https://www.airbnb.com/rooms/32441838?guests=1&amp;adults=1&amp;s=66&amp;unique_share_id=a9d17db0-4c98-4aca-9be0-778c7bbf333d&amp;source=embed_widget"
              rel="noreferrer"
              target="_blank"
            >
              Apartment with en suite Bathroom in Ebene Square
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
