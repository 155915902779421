import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

export default function NavbarBlogMenuItems() {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center gap-x-100.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          <Link to="/BlogLessonsLearnedPast15Years">Blog Articles</Link>
          <ChevronDownIcon
            className="-mr-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        {/* <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"> */}
        <Menu.Items className=" sm:right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <ul className="py-1">
              <li className="text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2">
                <Link to="/BlogLessonsLearnedPast15Years">
                  Lessons Learned Past 15 Years - 2023
                </Link>
              </li>
              <li className="text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2">
                <Link to="/BlogPostClosing2018">Trip closing 2018</Link>
              </li>
              <li className="text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2">
                <Link to="/EnrichingTravelsReloaded">
                  Enriching Travels Reloaded - 2018
                </Link>
              </li>
              <li className="text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2">
                <Link to="/BlogPostSingapore">Singapore - 2015</Link>
              </li>
              <li className="text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2">
                <Link to="/BlogPostNewYork">New York - 2014</Link>
              </li>
              <li className="text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2">
                <Link to="/BlogPostPanamaGanesh">Panama - 2014</Link>
              </li>
              <li className="text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2">
                <Link to="/BlogDenmark">Denmark - 2014</Link>
              </li>
            </ul>
            {/*             <Menu.Item>
              {({ active }) => (
                <a
                  href="/BlogLessonsLearnedPast15Years"
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "block px-4 py-2 text-sm"
                  )}
                >
                  Lessons Learned Past 15 Years
                </a>
              )}
            </Menu.Item> */}
            {/*             <Menu.Item>
              {({ active }) => (
                <Link
                  to="/BlogLessonsLearnedPast15Years"
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "block px-4 py-2 text-sm"
                  )}
                >
                  About
                </Link>
              )}
            </Menu.Item> */}
            {/*      <Menu.Item>
              {({ active }) => <BlogLessonsLearnedPast15Years />}
            </Menu.Item>

            <Menu.Item>
              {({ active }) => (
                <a
                  href="/BlogPostClosing2018"
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "block px-4 py-2 text-sm"
                  )}
                >
                  Trip closing 2018
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="/EnrichingTravelsReloaded"
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "block px-4 py-2 text-sm"
                  )}
                >
                  Enriching Travels Reloaded
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="/BlogPostSingapore"
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "block px-4 py-2 text-sm"
                  )}
                >
                  Singapore - 2015
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="/BlogPostNewYork"
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "block px-4 py-2 text-sm"
                  )}
                >
                  New York - 2014
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="/BlogPostPanamaGanesh"
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "block px-4 py-2 text-sm"
                  )}
                >
                  Panama - 2014
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="/BlogDenmark"
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "block px-4 py-2 text-sm"
                  )}
                >
                  Denmark - 2014
                </a>
              )}
            </Menu.Item> */}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
