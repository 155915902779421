import React, { Component } from "react";
import Navbar from "../headercomponents/navbar.jsx";
import Footer from "../FooterComponents/footer.js";
import Jumbotron from "../JumbotronComponents/jumbotron.jsx";
class BlogPandemic extends Component {
  render() {
    return (
      <div>
        {this.props.showArticleOnly ? (
          <h2>Work During the Covid Pandemic</h2>
        ) : (
          <div>
            <Navbar />
            <Jumbotron
              title="Work During the Covid Pandemic"
              subtitle="Changes in Work Situations during the Covid-19 Pandemic"
              className="denmark"
            />
          </div>
        )}

        <div className="container">
          <p>
            Since last year, in March 2020, as the Covid-19 Pandemic started to
            enfold, life for everyone has changed. Many of us have lost jobs.
            Many of us have lost loved and dear ones. Many of us have been
            attacked by the disease itself. All of us have been forced to accept
            the situation and adapt rapidly. Doctors and all those working in
            the medical sector have had to work day and night to take care of
            those who got sick from this disease. Others working in Shops and
            restaurants have been forced to stop working. Students have been
            forced to stay and study from home. Many are still in the same class
            as last year because they could not finish their curriculum and sit
            for their exams.
            <br />
            As a person who decided to leave for Germany in September 2019, to
            embrace a new adventure, a new career and a new life, things changed
            drastically, as I never would have expected. Of course, I knew
            things would not be easy and I would need to struggle. I was
            prepared for it. But I never expected that.
            <br />
            But, as we have seen, as a global community, we are resilient. We
            are able to rise above this , despite all the losses we need to
            bear. Some have been working hard to find the vaccines necessary to
            stop the pandemic. Others have found solutions to allow people to
            continue to sustain the jobs, through government programmes, working
            from home. So many services have been brought online to continue to
            serve the population. All companies have been allowing their
            employees to work from home.
            <br />
            This is the new Normal. And all will be fine. We can only embrace
            the change with positivity and see new opportunities in adversities,
            because life goes on.
            <br /> In Mauritius, just after the first wave last year, the
            economy was going down especially with the decline of the tourist
            industry. The Mauritius Government therefore took another initiative
            to bring foreigners into our country. Measures were thus taken to
            attract remote workers to come to Mauritius for long stays. Remote
            Workers from any part of the world can apply for a Premium visa. You
            can apply for the Premium Visa on the Government
            <a
              href="https://passport.govmu.org/passport/?page_id=595"
              rel="noopener noreferrer"
              target="_blank"
            >
              {" "}
              Website.
            </a>
            <br />
            You can also read an interesting{" "}
            <a
              href="https://www.lonelyplanet.com/articles/mauritius-long-term-stay-visa"
              rel="noopener noreferrer"
              target="_blank"
            >
              article
            </a>{" "}
            to know more about it.
          </p>
        </div>
        {this.props.showArticleOnly ? <div></div> : <Footer />}
      </div>
    );
  }
}

export default BlogPandemic;
