import React, { Component } from "react";
import Navbar from "../headercomponents/navbar.jsx";
import Footer from "../FooterComponents/footer.js";
import Jumbotron from "../JumbotronComponents/jumbotron.jsx";
class BlogPostNewYork extends Component {
  render() {
    return (
      <div>
        <Navbar />
        <div className="newyork">
          <Jumbotron
            title="New York 2014"
            subtitle="Escapade to The Big Apple"
          />
        </div>
        <div className="container">
          <p>
            My trip to New York City was one enriching travel experience that I
            will always cherish in my heart. I left Panama on the Saturday the
            13th of September, arrived at the JFK airport at 3:30 am on the
            Sunday. I spent one week there and returned to Panama the next
            Sunday evening. When I arrived at JFK airport, I was politely
            explained one the security officer there, how I could get to the
            Bowery House, the hostel where I would be staying. I had already
            Googled the direction before coming, but, I had to ask to know
            exactly where to go. Indeed, the directions made more sense to me
            when the security officer explained it to me again. Therefore, I
            took the air train to Jamaica Station. That was the first time that
            I was traveling in a country at this time of the hour. It felt weird
            but I also felt some excitement and happiness rising as I remember
            that this was my vacation starting!! Just three days ago, I had no
            idea whether I would even be going anyway!! First, I did some rapid
            research on the Internet; (Trip Advisor is a very good beginning for
            comparing accomodation and plane ticket prices, for reviewing your
            destination before going and for useful tips for your imminent
            trip). Then, my colleague helped me to decide where to book my
            flight. After reviewing other flight booking sites, I finally chose
            Copa Airlines because the ticket fare was the same to what was being
            offered by the others, including Trip Advisor. This was because it
            was last minute booking, I did not have much choice. So, lesson
            learned: Try to always book in advance! Unless you have the tendency
            to live on the edge like me!! I did that on the Thursday preceding
            my departure.
          </p>
          <p>
            The following day I booked the hostel, via hostelworld.com; there
            were again not many choices because at this time of the year, New
            York receives many visitors. However, I managed to get a single room
            in Bowery House. This hostel was not bad at all, I was in a room of
            about 1 metre square, with a small bed, a bedside table which had a
            set of drawers, a lamp and a hanger. That is it. According to its
            history, the Bowery House was at some point in the 1940s, the
            temporary lodging for soldiers returning from World War II. The
            historic cabins have been preserved to provide authentic
            accommodations to travellers to date. Therefore, it felt quite an
            experience to spend the nights there. Moreover, the price was very
            good and the bathroom were clean. However, please do check the
            reviews about this hostel to know whether you would be ready to
            share open ceiling with other cabins, hear snoring (which you
            probably would not if you would be spending the whole day/ most of
            the night walking and visiting New York and returned dead tired and
            slept the second you lied on the bed)... All in all, it depends how
            much you are willing to spend; like mentioned in many articles which
            I had myself read before going and with which I do agree now after
            the trip, it is better to invest the money on attractions or food,
            rather than accommodation because New York never sleeps and you
            would probably hardly sleep too when you go there for vacation!
            Last, but not least, I paid for a New York Explorer Pass before
            going. I chose this one because I had no idea which attractions that
            I would like to see; this one was ideal for my case because you pay
            for 10 attractions beforehand, but you do not have to choose the
            attractions at the time of payment; you can get 57 attractions to
            choose from as you go. However, please check the comparisons with
            the other passes, City Pass and New York Pass, to choose the one
            will suit your needs, depending on the number of days of the trip
            and the attractions which you would like to see. Also, you can also
            buy your pass at City Sight NY, on 42nd Avenue when you arrive in
            New York. However make sure that you do it the day you arrive
            because a few of the attractions require reservations 2-3 days
            before. For other tips for food and budget trip, you can check Trip
            Advisor. There was no laptop with me, no work related items, just a
            very heavy backpack lol. The backpack idea was from one of my male
            colleagues. Since I was very busy with work that week, it was only
            at the last minute, on Saturday that I took time to go and buy my
            backpack, necessities, and the things to allow me to pack minimum.
            For instance, I bought small plastic zipper bags to bring just what
            I needed. I even counted my vitamins pills for the week, put them in
            one those bags. However, needless to say that after packing, then
            repacking to remove excess items, the backpack was still very heavy,
            but fortunately on the second packing, I could carry it. I also had
            another handbag for my personal belongings lol. Not really
            packpacking I know…I remember that I discussed that with a couple at
            the Tocumen International Airport in Panama as we were waiting at
            the Departure gate; I told them that backpacking was probably not
            for me. My shoulder and feet were already paining from the weight.
            But, they reassured me that they used to do that too. I later
            realized the pain was probably due to the fact that I had been
            running around with my heavy shopping spree all Saturday morning!!
            ;-)
          </p>
          <p>
            After some 15 minutes in the airtrain, we reached the Jamaica
            Station where I transferred to the J train which would take me to
            the Bowery Station. It was around 4am and there were few people in
            the train. I asked one guy whether I was in the right train to
            Bowery. He said yes; I was very happy to hear him talking, it was
            that American accent that I had heard in American movies. Now that I
            think of it, I did interact with American colleagues in one of my
            past jobs, but the accent of the New Yorkers were altogether
            different. They are like singing even more. I remember that in many
            places, the guides or the police officers, would shout “Move guys!”
            but I would always smiled because even then the tone had that nice
            singing style or rhythm.
          </p>
          <p>
            When I got out into the street at the Bowery station, it was still
            dark; it was 5:00 am. I thought that maybe it would be better that I
            go back to the station and wait for sunrise. However, when I went
            down again, the officer at the station asked me what had happened,
            because I had already talked to him to ask him for direction to the
            Bowery House. He told me that it was only a few blocks away and that
            I could walk. I therefore set out. As I got out, I saw a young
            couple, sitting on the steps outside a building, they had a guitar
            with them. I guessed that they had just finished the performance
            somewhere nearby and was relaxing. That was the first time that I
            saw the relaxing side of the New York. I walked a few minutes and
            crossed the street; that was Bowery Street. I walked toward the
            street, checking the number on each door for the number 220. I
            missed it and had to return back to see it because it was so dark
            around. Fortunately, I had already seen a few pictures of the hostel
            before coming and I recognized the entrance. When I rang the bell,
            the door opened and I was indeed faced with a long black and white
            staircase which took me to the second level where the reception was.
            That marked the beginning of my trip. Further narrations will
            follow.
          </p>
          <h2>Lessons of the Trip:</h2>
          <ul>
            <li>
              That travel preparation and weekend taught that no matter how late
              it is, it is never too late. If we want to do something, we just
              need to dare; just start planning for it as a beginning and the
              rest will flow by itself. That first step will give us the
              motivation to continue to completion. And sometimes, along the
              way, we even get wonderful surprises which then make that risk
              that we took so worth it.
            </li>
            <li>
              We also learn that often less is more, even with less, we can
              always manage and enjoy as long as we set our mind to it.
            </li>
          </ul>
        </div>
        <Footer />
      </div>
    );
  }
}

export default BlogPostNewYork;
